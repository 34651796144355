html{
  overflow-x: hidden;
}

.navbar{
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
}

nav li{
  text-decoration: none;
  list-style: none;
}

.nav-link{
  cursor: pointer;
  transition: 0.2s;
}

.submission-logo{
  max-height: 80px;
}

.submission-wrapper{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.container-c {
  padding: 0 1rem;
  margin: auto;
}

.card{
  border-radius: 20px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.login-wrapper {
  max-width: 400px;
  margin: auto;
}

.error{
  border-color: red !important;
}

.message-error{
  color: red;
}
.message-success{
  color: green;
}
.date{
  color: white;
  font-size: 0.8rem;
}

.float-r{
  float: right;
  margin: 0;
}
.float-l{
  float: left;
  margin: 0;
}
.form-control-row{
  width: unset;
  margin-right: 5px;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-around{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.mla{
  margin-left: auto;
}
.mlta{
  margin-left: auto;
  margin-top: auto;
}

#tiles{
  margin: 0;
  gap: 1.5rem;
}

.tile{
  padding: 3rem 1rem;
  border: 1px solid grey;
  height: 200px;
  width: 200px;
  flex-direction: column;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 6px;
}

.tile:hover{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tile svg{
  font-size: 40px;
}
.padding-10{
  padding: 10px;
}
.close-navbar-toggler{
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  z-index:1;
  cursor:pointer;
}
.close-navbar-toggler.collapsed{
  display: none;
}
.dashboard-wrapper{
  margin-top: 80px;
}
.btn-black{
  background-color: #231F20;
  width: 100%;
  padding: 1rem 1rem;
  border: none;
  color: white;
}

.btn-black:hover{
  background-color: #292929;
  color: white;
}

.btn-green:focus{
  box-shadow: none;
}

/* home */
header{
  margin: 0 3rem;
}


#bossimg{
  background-image: url("./img/boss.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}
#workerimg{
  background-image: url("./img/worker.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}

.home-logo{
  max-height: 110px;
}

.home-wrapper .bg-yellow-radius-t{
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #FCCA18;
}

.btn-nav{
  background-color: transparent;
  border: none;
  border-radius: 20px;
  color: black;
  font-weight: bold;
  padding: 15px 25px;
  font-size: 18px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-nav:hover{
  color: rgb(64, 64, 64);
}

.home-wrapper .intro{
  text-align: center;
  padding: 0 1rem;
}

.homegrid .row{
  margin: 0;
}

.subsection{
  text-align: center;
  max-width: 900px;
}

.footer .row{
  margin: auto;
  text-align: center;
  max-width: 800px;
}

.intro-wrapper{
  background-image: url('./img/girl.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  background-position: center;
}

.home-wrapper h1{
  background-color: #ffffff5c;
  font-size: 34px;
  font-weight: bold;
  border: white solid 1px;
  padding: 2rem 1rem;
}

.home-wrapper h2{
  font-size: 26px;
}

.footer{
  background-color: black;
  color: white;
}
a{
  color: unset;
}
a:hover{
  text-decoration: none;
  color: lightgray;
}

.mainbody{
  
    display: grid;
    /* let content auto to occupy remaining height and pass value in fit-content with min-height for header and footer */
    grid-template-rows: fit-content(8rem) auto fit-content(8rem);
    grid-template-areas: "header" "main" "footer";

}

.main-header{
  background-color: yellow;
  grid-area: header;
}

.main-content{
  grid-area: main;
}

.main-footer{
  background-color: green;
  grid-area: footer;
}

.home-wrapper input:focus{
  background:none;
}

.home-wrapper .btn-black{
  width: unset;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 20px;
}
.col-text h3{
  font-weight: bold;
}
.col-text p{
  font-size: large;
  padding: 1rem 0;
}
.col-text{
  background-color: #e0e0e0;
  padding: 4.5rem;
}
.col-img img{
  max-width: 100%;
}
.btn-grid{
  border: none;
  background-color: #FCCA18;
  font-weight: bold;
  padding: 0.7rem 2rem;
  transition: 0.2s;
  color: black;
}
.btn-grid:hover{
  background-color: #ffdc5d;
}

.col-img{
  padding: 0;
}
.certificates{
  margin: auto;
  text-align: center;
  max-width: 200px;
}
.certificates img{
  max-height: 60px;
}
.subsection h2{
  font-weight: 400;
}
/* tablets */
@media (max-width: 768px) {
  header{
    margin: 0 0;
  }
  .col-text{
    padding: 2rem;
  }
  .col-text p {
    font-size: medium;
    padding: 0;
}
  .submission-logo{
    max-height: 80px;
  }
  .pb-5{
    padding-bottom: 1rem !important;
  }
  .pt-5{
    padding-top: 1rem !important;
  }
  .tile{
    height: 165px;
    width: 165px;
  }
  #tiles{
    margin: 0;
    gap: 1rem;
  }
  .float-l{
    float: none;
  }
  .float-r{
    margin: 10px 0;
    float: none;
  }
  .home-logo {
    max-height: 60px;
  }
  .home-wrapper .footer {
    font-size: 10px;
  }
  .padd-5{
    padding: 2rem 1rem;
  }
  .home-wrapper .btn-black {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .home-wrapper h3 {
    font-size: 20px;
  }

  .home-wrapper h2 {
    font-size: 18px;
  }
  .home-wrapper h1 {
    font-size: 22px;
  }
  .home-logo {
    max-height: 60px;
  }
  .btn-nav {
    padding: 0px 10px;
    font-size: 16px;
  }
  .subpage h2{
    font-size: 24px;
  }
} 
/* mobile */
@media (max-width: 480px) {
  .submission-logo{
    max-height: 60px;
  }
  .navbar-info{
    align-items: start;
    flex-direction: column;
  }
  #tiles{
    justify-content: center;
    margin: 0;
    gap: 0.5rem;
  }
} 